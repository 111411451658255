import Moment from 'react-moment';
import { Box, Grid, Typography } from '@mui/material';
import FormattedAddress from '../../components/FormattedAddress';
import { Client, isBusiness, isIndividual, isTrust } from '../../apis/checkout';
import { styled } from '@mui/system';

interface ClientDetailsProps {
    client: Client;
}

const ClientDetails = (props: ClientDetailsProps) => {
    const { client } = props;

    const BorderedBoxStyle = styled(Box)(({ theme }) => ({
        border: '1px solid',
        borderColor: theme.palette.black?.['15'],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        minWidth: '300px',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        background: theme.palette.transparentBackGround?.['100'],
    }));

    return (
        <BorderedBoxStyle>
            <Typography variant='h4'>Client details</Typography>

            <Grid container rowSpacing='12'>
                <Grid item xs={12}></Grid>

                <Detail title='Name'>{client.displayName}</Detail>

                {isIndividual(client) && client.personalDetails.dateOfBirth && (
                    <Detail title='Date of Birth'>
                        <Moment format={'DD MMM yyyy'}>{client.personalDetails.dateOfBirth}</Moment>
                    </Detail>
                )}
                {(isTrust(client) || isBusiness(client)) && (
                    <Detail title='Business number'>{client.businessNumber}</Detail>
                )}
                <Detail title='Email'>{client.contactDetails.email.address}</Detail>
                <Detail title='Mobile'>{client.contactDetails.preferredPhoneNumber.number}</Detail>
                {client.contactDetails.preferredAddress && (
                    <Detail title='Address'>
                        <FormattedAddress address={client.contactDetails.preferredAddress} />
                    </Detail>
                )}
            </Grid>
        </BorderedBoxStyle>
    );
};

const Detail = ({ title, children }: { title: string; children: string | JSX.Element }) => {
    return (
        <>
            <Grid item sm={6} xs={12}>
                <Typography variant='body1' component='p'>
                    {title}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={12} sx={{ textAlign: { sm: 'right' }, mb: { xs: 1, sm: 0 } }}>
                <Typography variant='body1' component='p'>
                    {children}
                </Typography>
            </Grid>
        </>
    );
};

export default ClientDetails;
