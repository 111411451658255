import axios, { AxiosRequestConfig } from 'axios';
import { InvoiceSummary } from '../type/Types';
import { nonRedirectingAxios } from './util';
import { PaymentConfig } from './payment';

export type BaseClient = {
    uuid: string;
    identifier: string;
    createdDate: string;
    contactDetails: ContactDetails;
    insuredType: InsuredType;
    displayName: string;
};

export type AuthorizedIndividual = {
    personalDetails: Pick<PersonalDetails, 'givenName' | 'surname'>;
    contactDetails: Pick<ContactDetails, 'email' | 'preferredPhoneNumber'>;
};

export enum InsuredType {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS',
    TRUST = 'TRUST',
}

export type IndividualClient = BaseClient & {
    personalDetails: PersonalDetails;
    insuredType: InsuredType.INDIVIDUAL;
};

export enum BusinessEntityType {
    SOLE_TRADER = 'SOLE_TRADER',
    LIMITED_LIABILITY = 'LIMITED_LIABILITY',
    REGISTERED_COMPANY = 'REGISTERED_COMPANY',
}

export type BusinessClient = BaseClient & {
    companiesOfficeNumber: string;
    businessNumber: string;
    entityType: BusinessEntityType;
    businessName: string;
    tradingName?: string;
    authorizedIndividual: AuthorizedIndividual;
};

export type TrustClient = BaseClient & {
    businessNumber: string;
    trustName: string;
    authorizedIndividual: AuthorizedIndividual;
};

export type Client = IndividualClient | BusinessClient | TrustClient;

export type LoanSummary = {
    insured: Client;
    paymentConfig: PaymentConfig;
};

export type ExpectedPayment = {
    dueDate: string;
    amount: number;
};

export type ContactDetails = {
    email: Email;
    preferredAddress: Address;
    preferredPhoneNumber: PhoneNumber;
};

export type PersonalDetails = {
    title: string;
    givenName: string;
    surname: string;
    middleName: string;
    gender: string;
    dateOfBirth?: string;
};

export type Address = {
    addressLine1: string;
    addressLine2: string;
    suburb: string;
    city: string;
    state: string;
    postCode: string;
    country: string;
    type: string;
};

export type PhoneNumber = {
    number: string;
    dialingCode: string;
};

export type Email = {
    address: string;
    emailType: string;
};

export type AccountSummary = {
    balance: number;
    nextPaymentDate: string;
    nextPaymentAmount: number;
    initialPayment: number;
    expectedPayments: ExpectedPayment[];
};

export const fetchInvoiceSummary = async (uuid: string): Promise<InvoiceSummary> => {
    return await axios.get(process.env.REACT_APP_CHECKOUT_HOST + '/checkout/' + uuid).then(({ data }) => data);
};

export const fetchInvoiceUuidByReference = async (reference: string, recaptchaToken?: string): Promise<string> => {
    const url = new URL(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/_query/by-reference`);
    url.searchParams.append('reference', reference);
    const config = getRecaptchaConfig(recaptchaToken);

    return await nonRedirectingAxios()
        .get(url.href, config)
        .then(({ data }) => data);
};

const getRecaptchaConfig = (recaptchaToken?: string): AxiosRequestConfig | undefined => {
    if (!recaptchaToken) {
        return;
    }
    return { headers: { X_RECAPTCHA_TOKEN: recaptchaToken } };
};

export enum InvoiceStatus {
    PROCESSING = 'PROCESSING',
    PAID = 'PAID',
    DECLINED = 'DECLINED',
    IN_PROGRESS = 'IN_PROGRESS',
    ATTENTION = 'ATTENTION',
}

export const fetchInvoiceStatus = async (uuid: string): Promise<InvoiceStatus> => {
    return await axios
        .get(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${uuid}/invoice-status`)
        .then(({ data }) => data);
};

export const fetchLoanSummary = async (invoice: InvoiceSummary): Promise<LoanSummary> => {
    const url = new URL(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/loan-summary`);
    url.searchParams.append('invoiceUuid', invoice.uuid);
    if (invoice.clientIdentifier) {
        url.searchParams.append('clientUuid', invoice.clientIdentifier);
    }

    return await axios.get(url.href).then(({ data }) => data);
};

export const fetchAccountSummary = async (invoice: InvoiceSummary): Promise<AccountSummary> => {
    return await axios
        .get(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoice.uuid}/account-summary`)
        .then(({ data }) => data);
};

export const fetchPaymentSession = async (invoiceIdentifier: string, integratedView?: boolean): Promise<string> => {
    const url = new URL(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceIdentifier}/payment/session`);
    if (integratedView) {
        url.searchParams.append('embedded', 'true');
    }
    return await axios.get(url.href).then(({ data }) => data);
};

export const fetchInvoiceSummaryRecoveredUsingCorrectionToken = async (
    uuid: string,
    token: string
): Promise<InvoiceSummary> => {
    return await axios
        .get(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${uuid}/recover-using-correction-token`, {
            params: {
                'correction-token': token,
            },
        })
        .then(({ data }) => data);
};

export const fetchPaymentStatus = async (uuid: string, paymentUuid: string): Promise<string> => {
    return await axios
        .get(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${uuid}/payment/${paymentUuid}`)
        .then(({ data }) => data);
};

export type AccountDetails = {
    email: string;
    phoneNumber: string;
};

export const fetchAccountDetails = async (invoiceUuid: string): Promise<AccountDetails> => {
    return await axios
        .get(`${process.env.REACT_APP_CHECKOUT_HOST}/checkout/${invoiceUuid}/account-details`)
        .then(({ data }) => data);
};

// type guards

export const isIndividual = (toBeDetermined?: Client): toBeDetermined is IndividualClient => {
    return toBeDetermined?.insuredType === InsuredType.INDIVIDUAL;
};

export const isBusiness = (toBeDetermined?: Client): toBeDetermined is BusinessClient => {
    return toBeDetermined?.insuredType === InsuredType.BUSINESS;
};

export const isTrust = (toBeDetermined?: Client): toBeDetermined is TrustClient => {
    return toBeDetermined?.insuredType === InsuredType.TRUST;
};
